import React, { Component } from 'react';
import {graphql} from 'gatsby';
import Helmet from "react-helmet";
import config from "data/SiteConfig";
import Layout from 'layout';
import TopBanner from 'partials/topBanner';
import Popup from 'partials/popup';
import Slider from "react-slick";

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as modalActions from 'redux/actions/modal';

class Maps extends Component {

    handleClick = (video) => {
        const { toggle_modal, put_data_modal } = this.props.modalActions;
        put_data_modal('video_link', video);
        toggle_modal('maps', true);
    }

    populateMaps = () => {
        const {maps} = this.props.data;

        return maps.edges.map((data,index) =>
            <div key={index} className="list" onClick={() => this.handleClick(data.node.media_list.data)}>
                <div className="list__image">
                    <img src={data.node.thumbnail ? data.node.thumbnail : "https://dummyimage.com/274x169/fff.jpg&text=Image+Not+Found"} alt=""/>
                    {/* <img className="play" src={require('assets/img/maps/Button_playmini.png')} alt=""/> */}
                </div>
                <div className="list__text">{data.node.name}</div>
            </div>
        )
    }

    render() {
        return (
            <Layout>
                <Helmet title={"Maps - " + config.siteTitle} />
                <div id="MapsLayout" className="content-container" >
                    <TopBanner 
                        image={require('assets/img/maps/BG_Maps.jpg')} 
                        firstString="Map Klasik"
                        secondString="5v5"
                        subtitle="Pahami peta pertempuran"
                        description="untuk mengalahkan lawan"
                    />
                    <div className="content">
                        <div className="content__title">DAFTAR MAPS</div>
                        <div className="content__list">
                            {this.populateMaps()}
                        </div>
                    </div>
                    <Popup />
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
	return { modal: state.modal }
}

function mapDispatchToProps(dispatch) {
	return {
		modalActions: bindActionCreators(modalActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Maps); 

/* eslint no-undef: "off" */
export const pageQuery = graphql`
{
    maps: allDirectusMap {
        edges {
          node {
            name
            thumbnail
            media_list {
              data {
                url
              }
            }
          }
        }
      }
  }
`;